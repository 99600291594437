<template>
<div class="date">
    <slot></slot>
    <template v-if="+d>0">
        <span class="time-item">{{d}}</span>
        <span>:</span>
    </template>
        <span class="time-item">{{h}}</span>
        <span>:</span>
        <span class="time-item">{{m}}</span>
        <span>:</span>
        <span class="time-item">{{s}}</span>
      </div>
</template>
<script>
function plusZero(input) {
   return +input < 10 ? '0' + input : input;
}
export default {
  name: 'CountDown',
  props: {
      endGetTime: {
          type: [String,Number],
          default: new Date().getTime()
      }
  },
  data() {
    return {
      d: '',
      h: '',
      m: '',
      s: '',
      timer: null
    };
  },
  created() {
    this.countTime();
  },
  destroyed() {
      this.timer = null;
      clearTimeout(this.timer);
  },
  methods: {
    countTime: function() {
      // 获取当前时间
      var date = new Date();
      var now = date.getTime();
      // 时间差
      var leftTime = this.endGetTime - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        this.d = plusZero(Math.floor(leftTime / 1000 / 60 / 60 / 24));// 天数我没用到，暂且写
        this.h = plusZero(Math.floor((leftTime / 1000 / 60 / 60) % 24));
        this.m = plusZero(Math.floor((leftTime / 1000 / 60) % 60));
        this.s = plusZero(Math.floor((leftTime / 1000) % 60));
      }
      // 递归每秒调用countTime方法，显示动态时间效果
      this.timer = setTimeout(this.countTime, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
    .date{
        color: #000;
        .time-item{
            display: inline-block;
            width: .5rem;
            line-height: .5rem;
            height: .5rem;
            text-align: center;
            background: $--color-danger;
            border-radius: 5px;
            color: #fff;
        }
    }
</style>