<template>
  <zmAppPage :show-footer="false" :showHeaderLeft="true" :show-header="true" :title="$route.meta.title">
    <van-cell-group class="mt20 mb20" :class="[currentBook.preferential > 0 ? 'w-cell' : '']">
      <van-cell title="商品名称" class="df-center cell-left" :value="currentBook.fullName"> </van-cell>
      <template v-if="currentBook.preferential > 0">
        <van-cell title="定价" class="df-center cell-left"> ¥{{ (currentBook.price / 100).toFixed(2) }} </van-cell>
        <van-cell title="活动优惠" class="df-center cell-left">
          <div class="df-center">
            <div class="fc-red flex-1">-¥{{ ((currentBook.price - currentBook.preferential) / 100).toFixed(2) }}</div>
            <count-down :end-get-time="endTime">
              <span class="fs12 fc-9">倒计时:</span>
            </count-down>
          </div>
        </van-cell>
        <van-cell class="df-center cell-left">
          <div slot="title" class="fb fs16">支付小计</div>
          <div class="fc-red fs16">¥{{ (currentBook.preferential / 100).toFixed(2) }}</div>
        </van-cell>
      </template>
      <van-cell v-else class="df-center cell-left">
        <!-- <van-cell class="df-center cell-left"> -->
        <div slot="title" class="fb fs16">支付小计</div>
        <div class="fc-red fs16">¥{{ (currentBook.price / 100).toFixed(2) }}</div>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="请选择支付方式">
      <van-radio-group v-model="paymentMethod">
        <van-cell title="微信" class="df-center" clickable @click="paymentMethod = 1">
          <ZmIcon slot="icon" icon-class="wxpay" class="fs40 mr10 green"></ZmIcon>
          <template #right-icon>
            <van-radio :name="1" />
          </template>
        </van-cell>
      </van-radio-group>
    </van-cell-group>
    <div class="pd20">
      <!-- <zm-btn @click="handlePay" class="mt40" size="large">
        确认支付
      </zm-btn> -->
      <div class="fs14 fc-9 mt10 tc">
        支付功能关停
        <!-- 支付解锁后，整本书永久使用，不限时间 -->
      </div>
    </div>
  </zmAppPage>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@/config';
import apiUser from '@/api/src/apiUser';
import { alertToast } from '@/utils/src/utilsAlert';
import CountDown from './CountDown';
import * as auth from '@/utils/auth';
import { wxPay } from '@/utils/src/utilsWeixin';
import * as tools from '@/utils/src/tools';
console.log(tools.os.isWechat);
export default {
  name: 'pay',
  components: { CountDown },
  data() {
    return {
      payMoney: config.payMoney,
      paymentMethod: 1,
      endTime: new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1 // 当天23:59
    };
  },
  computed: {
    ...mapGetters(['currentBook']),
    redirectRoute() {
      return this.$route.query.redirect;
    }
  },
  watch: {},
  created() {
    console.log('currentBook', this.currentBook);
  },
  methods: {
    async handlePay() {
      console.log('handlePay');
      if (tools.os.isWechat) {
        let res = await this.getPayInfo({
          tradeType: 'JSAPI',
          deviceInfo: 'WEB',
          appInfoCode: config.h5InfoCode
        });
        let data = {
          debug: true,
          appId: res.appId, // 公众号ID，由商户传入
          timeStamp: res.timeStamp, // 时间戳，自1970年以来的秒数
          nonceStr: res.nonceStr, // 随机串
          package: res.packageValue,
          signType: 'MD5', // 微信签名方式：
          paySign: res.sign // 微信签名
        };
        wxPay(data)
          .then(() => {
            this.$router.push(this.redirectRoute);
          })
          .catch(() => {
            alertToast('支付失败');
          });
      } else if (window.plus) {
        this.appPay();
      }
    },
    getPayInfo({ tradeType, deviceInfo, appInfoCode } = { tradeType: 'APP', deviceInfo: 'ANDROID', appInfoCode: '' }) {
      let appInfo = auth.loc.get('appInfo', {});
      console.log('getPayInfo');
      return apiUser
        .placeOrder({
          bookId: +this.currentBook.id,
          paymentMethod: +this.paymentMethod,
          appInfoCode: appInfoCode || appInfo.appId,
          tradeType,
          deviceInfo
        })
        .then(res => {
          return res.payItem;
        });
    },
    appPay() {
      // 获取支付通道
      let payChanel = '';
      let plus = window.plus;
      let self = this;
      // let appInfo = auth.loc.get('appInfo', {});
      plus.payment.getChannels(
        async function(channels) {
          for (var i in channels) {
            if (channels[i].id == 'wxpay') {
              payChanel = channels[i];
            }
          }
          let data = await self.getPayInfo();
          let payParam = {
            // 后台返回的支付参数最好全部都是小写（论坛看到的提醒）
            appid: data.appId,
            noncestr: data.nonceStr,
            package: data.packageValue,
            partnerid: data.partnerid || '1605938074',
            prepayid: data.prepayId,
            timestamp: data.timeStamp,
            sign: data.sign
          };
          // 请求支付操作
          plus.payment.request(
            payChanel,
            payParam,
            function(result) {
              // 支付成功处理
              self.$router.push(self.redirectRoute);
            },
            function(error) {
              console.dir(error);
              alertToast('支付失败');
              // 支付失败处理
            }
          );
        },
        function(e) {
          alertToast('获取支付通道失败');
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.green {
  color: #07c160;
}
</style>
